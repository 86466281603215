import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import thumb from "assets/img/Training.jpg";
import thumb2 from "assets/img/Recommend.jpg";
import thumb3 from "assets/img/Storage.jpg";
import Section from 'components/HOC/Section';

const AboutPageArea2 = () => {
    return (
        <Section id="services">
        <div className="brook-video-area bg_color--1 ptb--130 ptb-md--80 ptb-sm--60">
            <Container>
                <Row>
                    <Col lg={6}>
                    <div className={`video-with-thumb text-center `}>
                        <div className="thumb">
                            <img src={thumb} alt="video thumb"/>
                        </div>
                    </div>
                    </Col>

                    <Col lg={6} className="mt_sm--30 mt_md--30">
                        <div className="video-content move-up wow">
                            <h3 className="heading heading-h3">Our selection</h3>
                            <div className="bk-separator--25"/>
                            <p className="bk_pra">
                                We offer a selection of high-quality wines from makers dedicated 
                                to crafting unique expressions of their terroir while also
                                providing great value. <br/><br/>
                                Our focus is primarily on boutique producers 
                                from Australia and New Zealand, alongside renowned wineries from classic old-world regions. 
                                Each producer and wine is carefully chosen by 
                                us to ensure they meet our standards for quality.
                                Many of these wines come from producers 
                            </p>
                            <div className="bk-separator--40"/>
                        </div>
                    </Col>
                </Row>
                <div className="bk-separator--40"/>
                <div className="bk-separator--40"/>
                <Row>
                
                    <Col lg={6} className="mt_sm--30 mt_md--30">
                        <div className="video-content move-up wow">
                            <h3 className="heading heading-h3">Personalized Wine Recommendation</h3>
                            <div className="bk-separator--25"/>
                            <p className="bk_pra">
                            Our knowledgeable staff is here to help you find the perfect bottle 
                            tailored to your tastes and preferences. 
                            Whether you're selecting wine for a special occasion or exploring new varieties, 
                            we'll guide you through our selection.
                            </p>
                            <div className="bk-separator--40"/>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className={`video-with-thumb text-center `}>
                            <div className="thumb">
                                <img src={thumb2} alt="video thumb"/>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="bk-separator--40"/>
                <div className="bk-separator--40"/>

                <Row>
                    <Col lg={6}>
                    <div className={`video-with-thumb text-center `}>
                        <div className="thumb">
                            <img src={thumb3} alt="video thumb"/>
                        </div>
                    </div>
                    </Col>

                    <Col lg={6} className="mt_sm--30 mt_md--30">
                        <div className="video-content move-up wow">
                            <h3 className="heading heading-h3">Wine Storage Solutions</h3>
                            <div className="bk-separator--25"/>
                            <p className="bk_pra">
                            Protect your treasures with our expert storage advice. 
                            Whether you're creating a wine cellar or seeking short-term solutions, we'll guide you in preserving your bottles in perfect condition, so they're ready when you are.
                            </p>
                            <div className="bk-separator--40"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </Section>
    );
};

export default AboutPageArea2;
