import React from 'react';
import Section from 'components/HOC/Section';

const IconBoxItemOne = ({data, border, padding, iconType, className, showBtn}) => {
    return (
        <Section id="aboutUs">
            <div
                className={`${className} icon-box mt--30 text-center ${border ? '' : 'no-border'} ${!border && padding ? 'with-padding' : ''}`}>
                <div className="inner">
                    <div className="content">
                        <h5 className="heading heading-h5">About Us</h5>
                        <p className="bk_pra">
                            Our team comprises knowledgeable wine 
                            industry experts who have built strong 
                            relationships across all areas of the industry, 
                            including both on-premise and off-premise markets, 
                            wholesalers, and national retailers. 
                            Obtaining wine listings is essential for the growth 
                            and development of brands, which is why our teams are 
                            structured to concentrate on specific segments where 
                            each brand has the potential to thrive.</p>
                    </div>
                </div>
            </div>
        </Section>
        
    );
};

export default IconBoxItemOne;